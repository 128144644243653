import React from 'react'
import AuthPage from 'pages/auth'
import ForgotPasswordPage from 'pages/auth/forgot-password'
import Login from 'pages/auth/login'
import Register from 'pages/auth/register'
import RequestAccount from 'pages/auth/request-account'

const authRouter = {
  path: 'auth',
  element: <AuthPage />,
  children: [{
    path: 'request-account',
    element: <RequestAccount />
  },{
    path: 'register',
    element: <Register />
  }, {
    path: '',
    element: <Login />
  },{
    path: 'forgot-password',
    element: <ForgotPasswordPage />
  }]
}

export default authRouter
