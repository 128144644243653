import React from 'react'
import { useForm } from 'react-hook-form'
import { Keys } from 'react-hook-form/dist/types/path/common'
import { RegisterOptions } from 'react-hook-form/dist/types/validator'
import { useParams } from 'react-router-dom'

import Rest from 'modules/rest'

type PageCreateInputs = {
  title: string
  url_path: string
  in_draft: boolean
}

type PageCreateResponse = {
  id: string
}

export function usePageCreate() {
  const [loading, setLoading] = React.useState(false)
  const [createdPage, setCreatedPage] = React.useState<PageCreateResponse | null>(null)

  const { workspaceId } = useParams()

  const {
    register: formRegister,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<PageCreateInputs>()

  function create(data: PageCreateInputs) {
    setLoading(true)
    return Rest.pages.post({ workspace: workspaceId, ...data }).then((response: PageCreateResponse) => {
      console.log(response)

      setCreatedPage(response)
      return response
    }).catch((error) => {
      // TODO: error message
      console.log(error)
    }).finally(() => {
      setLoading(false)
    })
  }

  return {
    register: (name: Keys<PageCreateInputs>, options?: RegisterOptions<PageCreateInputs, Keys<PageCreateInputs>>) => ({ ...formRegister(name, options), watch }),
    handleSubmit: handleSubmit(create),
    formState: { errors },
    loading,
    watch,
    createdPage
  }
}
