import React from 'react'

export default function WorkspaceTagsPage() {

  return (
    <>
      WorkspaceTagsPage
    </>
  )
}
