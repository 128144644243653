import { styled } from '@mui/joy/styles'

const ContainerWrapper = styled('div')(({ theme }) => ({
  height: '80vh',
  position: 'relative'

}))
export default function LandingMain() {

  return (

    <ContainerWrapper>
      Landing main
    </ContainerWrapper>
  )
}