import React from 'react'
import Card from '@mui/joy/Card'
import CardContent from '@mui/joy/CardContent'
import CardOverflow from '@mui/joy/CardOverflow'
import Divider from '@mui/joy/Divider'
import Grid from '@mui/joy/Grid'
import Link from '@mui/joy/Link'
import Typography from '@mui/joy/Typography'

import type { Workspace } from 'modules/state/state.types'

interface WorkspaceItemProps extends Workspace {

}

export default function WorkspaceItem({ id, title, description }: WorkspaceItemProps) {

  return (
    <Grid
      md={3}
      sm={6}
      xs={12}>
      <Card variant={'outlined'}>
        <CardContent>
          <Typography level={'title-md'}>{title}</Typography>
          <Typography level={'body-sm'}>{description}</Typography>
          <Link href={`/workspaces/${id}`}>
            Go to
          </Link>
        </CardContent>
        <CardOverflow
          sx={{ bgcolor: 'background.level1' }}
          variant={'soft'}>
          <Divider inset={'context'} />
          <CardContent orientation={'horizontal'}>
            <Typography
              fontWeight={'md'}
              level={'body-xs'}
              textColor={'text.secondary'}>
                    6.3k views
            </Typography>
            <Divider orientation={'vertical'} />
            <Typography
              fontWeight={'md'}
              level={'body-xs'}
              textColor={'text.secondary'}>
                    1 hour ago
            </Typography>
          </CardContent>
        </CardOverflow>
      </Card>
    </Grid>
  )
}
