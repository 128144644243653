import React from 'react'
import { useParams } from 'react-router-dom'
import Link from '@mui/joy/Link'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import ListItemContent from '@mui/joy/ListItemContent'

import usePages from 'modules/state/page.hook'

export default function PagesManagePage() {
  const { workspaceId } = useParams()

  const { pages } = usePages(workspaceId)

  return (
    <>
      Pages
      <Link href={'create'}>
        Create new Page
      </Link>

      <List>
        {pages?.map((page) => (
          <ListItem
            key={page.id}
            component={Link}
            href={page.id}>
            <ListItemContent>{page.title}</ListItemContent>
          </ListItem>
        ))}
      </List>
    </>
  )
}
