import { useLocation } from 'react-router-dom'
import Link from '@mui/joy/Link'
import ListItem from '@mui/joy/ListItem'
import ListItemButton from '@mui/joy/ListItemButton'
import ListItemContent from '@mui/joy/ListItemContent'
import Typography from '@mui/joy/Typography'

interface SidebarItemProps {
  icon: JSX.Element
  title: string
  href: string
  exact?: boolean
}

export default function SidebarItem({ icon, title, href, exact = false }: SidebarItemProps) {
  const location = useLocation()
  const { pathname } = location

  const isActive = exact ? pathname === href : pathname.startsWith(href)

  return (
    <ListItem variant={isActive ? 'soft' : undefined}>
      <ListItemButton
        component={Link}
        href={href}
      >
        {icon}
        <ListItemContent>
          <Typography>
            {title}
          </Typography>
        </ListItemContent>
      </ListItemButton>
    </ListItem>
  )
}