import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import useMe from '../modules/state/me.hook'

export default function useAuthRedirect(isLoading: boolean = true) {

  const location = useLocation()
  const navigate = useNavigate()

  const me = useMe()

  const { pathname } = location
  console.log('pathname', pathname, me.isLoggedIn)
  useEffect(() => {
    if (isLoading) {
      return
    }
    if (me.isLoggedIn) {
      // Handle when user is logged in but is on some auth page
      if (pathname.startsWith('/auth') || pathname === '/') {
        navigate('/workspaces', { replace: true })
      }
    } else {
      if (pathname.startsWith('/workspaces') || pathname.startsWith('/profile')) {
        navigate('/', { replace: true })
      }
    }

  }, [isLoading,pathname, me.isLoggedIn])
}