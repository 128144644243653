import React from 'react'
import { Outlet } from 'react-router-dom'
import Box from '@mui/joy/Box'
import useAuthRedirect from 'hooks/use-auth-redirect.hook'

import Footer from 'components/footer'
import Header from 'components/header'
import Sidebar from 'components/sidebar'
import i18n from 'modules/i18n'
import useMe from 'modules/state/me.hook'
import useWorkspaces from 'modules/state/workspace.hook'

export default function RootPage() {
  const [loading, setLoading] = React.useState(true)
  const me = useMe(false)
  const workspaces = useWorkspaces(false)

  useAuthRedirect(loading)

  React.useEffect(() => {
    Promise.all([
      i18n,
      me.invalidate(),
      workspaces.invalidate()
    ]).finally(() => {
      setLoading(false)
    })
  }, [])

  if (loading) {
    // TODO: Initial app loader
    return null
  }

  return (
    <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column'
      }}>
      <Header />
      <Sidebar />
      <Outlet />
      <Box flexGrow={1} />
      <Footer />
    </div>
  )
}
