import React from 'react'

export default function WorkspacePlanPage() {

  return (
    <>
      WorkspacePlanPage
    </>
  )
}
