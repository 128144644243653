import Methods from './methods'

const Rest = {
  auth: new Methods( '/api/auth/'),

  register: new Methods( '/api/auth/register/'),
  activation: new Methods('/api/auth/activation'),
  requestAccount: new Methods('/api/auth/request-account'),

  resetPassword: new Methods('/api/auth/reset_password'),
  resetPasswordSet: new Methods('/api/auth/reset_password/set'),

  users: new Methods('/api/users/'),
  passwordChange: new Methods('/api/users/:id/password/'),

  workspaces: new Methods('/api/workspaces/'),
  members: new Methods('/api/members/'),

  pages: new Methods('/api/pages/'),
  blocks: new Methods('/api/blocks/'),
  items: new Methods('/api/items/'),

  tags: new Methods('/api/tags/'),
  files: new Methods('/api/files/'),

  errors: new Methods('/api/errors/'),

  ping: new Methods('/api/system/ping/')
}

export default Rest
