import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/joy/Button'

import FormField from 'components/form/form-field'
import isEmpty from 'utils/isEmpty'

import { useWorkspaceCreate } from './workspaces-create.hook'

export default function WorkspacesCreate() {
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors },
    loading,
    createdWorkspace
  } = useWorkspaceCreate()

  useEffect(() => {
    if (!isEmpty(createdWorkspace)) {
      setTimeout(() => {
        navigate(`/workspaces/${createdWorkspace?.id}`, { replace: true })

      }, 1000)
    }
  }, [createdWorkspace])

  if (!isEmpty(createdWorkspace)) {
    return (
      <>
        Workspace is created.
        <br />
        Opening to new workspace
      </>
    )
  }
  return (
    <form
      onSubmit={handleSubmit}
      noValidate>
      <FormField
        field={errors.title}
        placeholder={'Title'}
        type={'text'}
        {...register('title', { required: true })}
      />
      <Button
        loading={loading}
        type={'submit'}>
        Create
      </Button>
    </form>
  )
}
