import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '@mui/joy/Button'
import Card from '@mui/joy/Card'
import CardContent from '@mui/joy/CardContent'
import Divider from '@mui/joy/Divider'
import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'

import Rest from 'modules/rest'

export default function WorkspaceSettingsPage() {
  const navigate = useNavigate()

  const { workspaceId } = useParams()

  function handleDeleteWorkspace() {
    if (workspaceId) {
      Rest.workspaces.remove(workspaceId).then(() => {
        navigate('/workspaces', { replace: true })
      })
    }
  }

  return (
    <>
      Workspaces Detail settings

      <Card >
        <Typography
          color={'danger'}
          fontSize={'lg'}
          fontWeight={'bold'}>
            Danger zone
        </Typography>
        <Divider />
        <CardContent>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}>
            <div>
              <Typography
                color={'danger'}
                level={'title-md'}>
                Delete workspace
              </Typography>
              <Typography
                level={'title-sm'}>
                Once deleted there is no way to be restored
              </Typography>
            </div>
            <Button
              color={'danger'}
              onClick={handleDeleteWorkspace}
              size={'md'}
              variant={'solid'}
            >
              Delete
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </>
  )
}
