import { useTheme } from '@mui/joy/styles'

export default function Logo() {
  const theme = useTheme()

  return (
    <svg
      height={30}
      viewBox={'65 173.409 420 123.182'}
    >
      <g>
        <g fillRule={'nonzero'}>
          <path
            d={'M283.487 214.075c0 2.19-.421 4.268-1.264 6.234-.842 1.965-1.98 3.678-3.412 5.139-1.432 1.46-3.13 2.625-5.096 3.496a15.225 15.225 0 0 1-6.234 1.306h-23.252c-1.46 0-2.71-.52-3.749-1.559-1.039-1.039-1.558-2.289-1.558-3.749s.52-2.71 1.558-3.749c1.04-1.039 2.289-1.558 3.75-1.558h23.082c1.516 0 2.822-.548 3.917-1.643 1.096-1.095 1.643-2.401 1.643-3.917 0-1.573-.547-2.907-1.643-4.002-1.095-1.095-2.4-1.643-3.917-1.643H256.36c-2.246 0-4.352-.421-6.318-1.264-1.966-.842-3.679-1.993-5.139-3.454-1.46-1.46-2.612-3.173-3.454-5.138-.842-1.966-1.264-4.072-1.264-6.319 0-2.246.422-4.352 1.264-6.318.842-1.966 1.994-3.679 3.454-5.14a16.977 16.977 0 0 1 5.097-3.495c1.937-.87 4.03-1.306 6.276-1.306h10.7c1.46 0 2.709.52 3.748 1.559 1.039 1.039 1.559 2.288 1.559 3.748 0 1.46-.52 2.71-1.559 3.75-1.039 1.038-2.289 1.558-3.749 1.558h-10.699c-1.516.056-2.808.618-3.875 1.685-1.067 1.067-1.6 2.387-1.6 3.96 0 1.516.533 2.821 1.6 3.917 1.067 1.095 2.387 1.642 3.96 1.642h10.951c2.247 0 4.353.436 6.318 1.306 1.966.87 3.68 2.036 5.14 3.496 1.46 1.46 2.61 3.173 3.453 5.14.843 1.965 1.264 4.071 1.264 6.318z'}
            fill={theme.palette.primary.softColor}
          />
          <path
            d={'M324.093 219.803c1.46 0 2.696.52 3.707 1.559 1.01 1.039 1.516 2.288 1.516 3.749 0 1.46-.505 2.695-1.516 3.706-1.011 1.011-2.247 1.517-3.707 1.517h-6.234c-3.707 0-7.203-.716-10.489-2.149-3.285-1.432-6.15-3.37-8.593-5.812-2.443-2.443-4.38-5.308-5.812-8.593-1.433-3.286-2.149-6.81-2.149-10.573 0-3.707.716-7.203 2.149-10.488 1.432-3.286 3.37-6.15 5.812-8.593 2.444-2.443 5.308-4.381 8.593-5.813 3.286-1.432 6.782-2.149 10.489-2.149h6.234c1.46 0 2.696.506 3.707 1.517 1.01 1.01 1.516 2.246 1.516 3.707 0 1.46-.505 2.71-1.516 3.749-1.011 1.039-2.247 1.558-3.707 1.558h-6.234c-2.303 0-4.451.435-6.445 1.306a17.364 17.364 0 0 0-5.265 3.538c-1.517 1.488-2.71 3.244-3.58 5.265a16.02 16.02 0 0 0-1.306 6.403c0 2.303.435 4.465 1.305 6.487.871 2.022 2.064 3.777 3.58 5.265a17.364 17.364 0 0 0 5.266 3.538c1.994.87 4.142 1.306 6.445 1.306z'}
            fill={theme.palette.primary.softColor}
          />
          <path
            d={'M360.15 175.996c3.763 0 7.287.716 10.572 2.148 3.286 1.432 6.15 3.37 8.593 5.813 2.443 2.443 4.38 5.322 5.813 8.635 1.432 3.314 2.148 6.824 2.148 10.53v21.904c0 1.46-.505 2.696-1.516 3.707-1.011 1.011-2.247 1.517-3.707 1.517s-2.71-.506-3.749-1.517c-1.039-1.01-1.558-2.246-1.558-3.707v-10.109H360.15v-.084c-.169.056-.478.084-.927.084-1.853 0-3.623.28-5.308.842a16.522 16.522 0 0 0-4.59 2.36 16.233 16.233 0 0 0-3.581 3.622 15.486 15.486 0 0 0-2.275 4.633c-.056.056-.084.113-.084.169 0 .056-.028.112-.084.168a4.898 4.898 0 0 1-1.854 2.57c-.898.646-1.937.969-3.117.969-1.46 0-2.696-.506-3.707-1.517-1.01-1.01-1.516-2.246-1.516-3.707v-21.903c0-3.314.562-6.43 1.685-9.351 1.348-3.595 3.37-6.796 6.066-9.604a28.719 28.719 0 0 1 8.719-5.982c3.285-1.46 6.81-2.19 10.573-2.19zm16.596 28.39v-1.263c0-2.92-.702-5.617-2.106-8.088 0-.056-.014-.098-.042-.126-.029-.028-.043-.07-.043-.126l-.674-1.011a13.24 13.24 0 0 0-.674-.927 16.16 16.16 0 0 0-5.644-4.634c-2.246-1.123-4.718-1.684-7.413-1.684-2.696 0-5.181.603-7.456 1.81-2.275 1.208-4.17 2.795-5.687 4.76a16.755 16.755 0 0 0-2.485 4.634c-.59 1.685-.884 3.482-.884 5.392v6.234a26.652 26.652 0 0 1 7.245-3.665c2.64-.87 5.42-1.306 8.34-1.306h.927z'}
            fill={theme.palette.primary.softColor}
          />
          <path
            d={'M427.545 225.026c0 1.404-.491 2.598-1.474 3.58-.983.984-2.176 1.531-3.58 1.644h-.759c-3.65-.113-7.076-.9-10.277-2.36-3.202-1.46-5.982-3.411-8.34-5.854-2.36-2.443-4.227-5.28-5.603-8.509-1.376-3.23-2.064-6.697-2.064-10.404v-21.904c0-1.46.506-2.696 1.516-3.707 1.011-1.01 2.247-1.516 3.707-1.516s2.71.505 3.75 1.516c1.038 1.011 1.558 2.247 1.558 3.707l-.085 21.904c0 2.246.422 4.352 1.264 6.318a17.13 17.13 0 0 0 3.412 5.181c1.432 1.488 3.117 2.696 5.055 3.623a15.085 15.085 0 0 0 6.192 1.474h.42c1.461 0 2.71.52 3.75 1.558 1.039 1.04 1.558 2.29 1.558 3.75z'}
            fill={theme.palette.primary.softColor}
          />
          <path
            d={'M485 203.207c-.056 1.46-.604 2.696-1.643 3.707-1.039 1.01-2.288 1.544-3.749 1.6H442.12c.224.674.533 1.376.926 2.107.281.561.562 1.095.843 1.6l.842 1.517c-.28-.45-.533-.885-.758-1.306a55.92 55.92 0 0 1-.674-1.306 17.243 17.243 0 0 0 3.454 4.38 16.139 16.139 0 0 0 4.718 3.034c1.853.786 3.903 1.235 6.15 1.347h.337c1.46 0 2.681.492 3.664 1.475.983.983 1.474 2.204 1.474 3.664s-.491 2.696-1.474 3.707c-.983 1.011-2.204 1.517-3.664 1.517h-.97c-.308 0-.603-.029-.884-.085a26.547 26.547 0 0 1-6.908-1.432 28.942 28.942 0 0 1-6.276-3.075 26.797 26.797 0 0 1-5.265-4.507 25.92 25.92 0 0 1-3.96-5.813c-2.078-4.212-3.019-8.663-2.822-13.353.196-4.69 1.53-9.028 4.002-13.015.224-.337.463-.688.716-1.053.252-.365.52-.716.8-1.053a25.652 25.652 0 0 1 7.582-6.866 28.583 28.583 0 0 1 9.52-3.58c4.324-.675 8.592-.338 12.805 1.01 4.212 1.348 7.834 3.595 10.867 6.74a28.035 28.035 0 0 1 5.771 8.593c1.376 3.201 2.064 6.57 2.064 10.109v.169zm-35.972-14.153a16.948 16.948 0 0 0-4.213 3.917 17.69 17.69 0 0 0-2.695 5.013h31.675a17.938 17.938 0 0 0-1.179-2.696 16.038 16.038 0 0 0-4.339-5.181 17.173 17.173 0 0 0-6.107-3.075c-2.19-.618-4.437-.744-6.74-.38a16.873 16.873 0 0 0-6.402 2.402z'}
            fill={theme.palette.primary.softColor}
          />
          <path
            d={'M261.531 240.172c4.325-.73 8.58-.421 12.763.926 4.185 1.348 7.821 3.595 10.91 6.74a26.697 26.697 0 0 1 5.855 8.804 25.985 25.985 0 0 1 1.98 10.404c0 2.864-.464 5.658-1.39 8.382a27.919 27.919 0 0 1-3.918 7.54c-2.752 3.707-6.234 6.529-10.446 8.467-4.212 1.937-8.593 2.766-13.142 2.485a26.547 26.547 0 0 1-6.908-1.432 27.849 27.849 0 0 1-11.542-7.667 25.92 25.92 0 0 1-3.96-5.813 28.152 28.152 0 0 1-2.232-6.486 26.26 26.26 0 0 1-.547-6.782 29.182 29.182 0 0 1 1.137-6.74 24.728 24.728 0 0 1 2.822-6.276c.393-.73.87-1.46 1.432-2.19h.085a25.137 25.137 0 0 1 7.582-6.824 29.118 29.118 0 0 1 9.52-3.538zm18.197 36.14a16.92 16.92 0 0 0 2.485-5.644c.478-2.022.576-4.072.295-6.15-.337-2.078-.955-3.903-1.853-5.475a17.822 17.822 0 0 0-4.38-5.224 15.447 15.447 0 0 0-6.067-3.032 16.014 16.014 0 0 0-6.74-.422 16.35 16.35 0 0 0-6.402 2.36c-2.527 1.628-4.493 3.79-5.897 6.486a16.317 16.317 0 0 0-1.853 8.593c.112 2.359.702 4.55 1.769 6.571.28.505.548 1.025.8 1.559a12.3 12.3 0 0 0 .885 1.558 38.798 38.798 0 0 1-1.432-2.612 16.576 16.576 0 0 0 3.454 4.34 18.212 18.212 0 0 0 4.717 3.074 15.632 15.632 0 0 0 5.645 1.306 15.823 15.823 0 0 0 5.728-.8 16.624 16.624 0 0 0 4.97-2.528 17.276 17.276 0 0 0 3.876-3.96z'}
            fill={theme.palette.primary.plainColor}
          />
          <path
            d={'M330.78 283.473c1.46 0 2.696.52 3.707 1.559 1.011 1.039 1.516 2.289 1.516 3.749s-.505 2.696-1.516 3.707c-1.01 1.01-2.246 1.516-3.707 1.516h-6.234c-3.707 0-7.203-.716-10.488-2.148-3.286-1.432-6.15-3.37-8.593-5.813-2.443-2.443-4.38-5.308-5.813-8.593-1.432-3.286-2.148-6.81-2.148-10.573 0-3.707.716-7.203 2.148-10.488 1.432-3.286 3.37-6.15 5.813-8.593 2.443-2.443 5.307-4.38 8.593-5.813 3.285-1.432 6.781-2.148 10.488-2.148h6.234c1.46 0 2.696.505 3.707 1.516 1.011 1.011 1.516 2.247 1.516 3.707s-.505 2.71-1.516 3.749c-1.01 1.039-2.246 1.558-3.707 1.558h-6.234c-2.302 0-4.45.436-6.444 1.306a17.364 17.364 0 0 0-5.266 3.538c-1.516 1.489-2.71 3.244-3.58 5.266a16.02 16.02 0 0 0-1.306 6.402c0 2.303.435 4.465 1.306 6.487.87 2.022 2.064 3.777 3.58 5.265a17.364 17.364 0 0 0 5.266 3.539c1.993.87 4.142 1.305 6.444 1.305z'}
            fill={theme.palette.primary.plainColor}
          />
          <path
            d={'M382.338 239.666c1.46 0 2.696.52 3.707 1.559 1.01 1.039 1.516 2.288 1.516 3.749 0 1.46-.505 2.695-1.516 3.706-1.011 1.011-2.247 1.517-3.707 1.517h-13.479v38.5c0 1.46-.52 2.695-1.559 3.706-1.039 1.011-2.288 1.517-3.749 1.517-1.46 0-2.695-.506-3.706-1.517-1.011-1.01-1.517-2.246-1.517-3.706v-38.5H344.85c-1.46 0-2.696-.506-3.707-1.517-1.01-1.01-1.516-2.246-1.516-3.706s.505-2.71 1.516-3.75c1.011-1.038 2.247-1.558 3.707-1.558H375.851z'}
            fill={theme.palette.primary.plainColor}
          />
          <path
            d={'M412.75 240.172c4.325-.73 8.58-.421 12.763.926 4.184 1.348 7.821 3.595 10.91 6.74a26.697 26.697 0 0 1 5.855 8.804 25.985 25.985 0 0 1 1.98 10.404c0 2.864-.464 5.658-1.39 8.382a27.919 27.919 0 0 1-3.918 7.54c-2.752 3.707-6.234 6.529-10.446 8.467-4.212 1.937-8.593 2.766-13.142 2.485a26.547 26.547 0 0 1-6.908-1.432 27.849 27.849 0 0 1-11.542-7.667 25.92 25.92 0 0 1-3.96-5.813 28.152 28.152 0 0 1-2.232-6.486 26.26 26.26 0 0 1-.547-6.782 29.182 29.182 0 0 1 1.137-6.74 24.728 24.728 0 0 1 2.822-6.276c.393-.73.87-1.46 1.432-2.19h.085a25.137 25.137 0 0 1 7.582-6.824 29.118 29.118 0 0 1 9.52-3.538zm18.197 36.14a16.92 16.92 0 0 0 2.485-5.644c.478-2.022.576-4.072.295-6.15-.337-2.078-.955-3.903-1.853-5.475a17.822 17.822 0 0 0-4.38-5.224 15.447 15.447 0 0 0-6.067-3.032 16.014 16.014 0 0 0-6.74-.422 16.35 16.35 0 0 0-6.402 2.36c-2.527 1.628-4.493 3.79-5.897 6.486a16.317 16.317 0 0 0-1.853 8.593c.112 2.359.702 4.55 1.769 6.571.28.505.548 1.025.8 1.559a12.3 12.3 0 0 0 .885 1.558 38.798 38.798 0 0 1-1.432-2.612 16.576 16.576 0 0 0 3.454 4.34 18.212 18.212 0 0 0 4.717 3.074 15.632 15.632 0 0 0 5.645 1.306 15.823 15.823 0 0 0 5.728-.8 16.624 16.624 0 0 0 4.97-2.528 17.276 17.276 0 0 0 3.876-3.96z'}
            fill={theme.palette.primary.plainColor}
          />
        </g>
        <g
          fillRule={'evenodd'}
        >
          <g data-paper-data={'{"isPathIcon":true}'}>
            <path
              d={'M181.959 186.123c11.977 10.117 13.955 24.412 15.7 37.025.033.239 2.137 24.816-6.479 30.557a7.307 7.307 0 0 1-4.062 1.23c-1.379 0-3.007-.377-4.845-1.461-6.248-3.682-3.825-10.25-2.523-13.78.297-.807.588-1.585.78-2.297 1.471-5.525.349-13.295-.553-19.54l-.329-2.323c-.756-5.455-3.505-10.698-7.949-15.168-3.786-3.804-8.866-6.544-13.781-9.195-1.015-.55-2.703-.678-4.335-.802-2.701-.207-5.764-.44-8.147-2.39-2.623-2.148-3.841-5.124-3.257-7.962.586-2.841 2.806-5.015 6.095-5.96 11.87-3.414 28.341 7.552 33.685 12.066z'}
              fill={theme.palette.primary.softColor}
            />
            <path
              d={'M168.947 260.525c-.409.257-.825.554-1.252.898-1.532 1.228-2.601 2.668-3.636 4.063-1.009 1.357-1.958 2.638-3.244 3.53-3.5 2.44-7.742 4.84-11.935 6.763-5.052 2.313-8.625 2.568-14.254 2.237-16.02-.928-43.83-5.782-50.121-27.87-4.875-17.123-.9-34.876 10.376-46.323 4.869-4.945 11.38-7.394 18.272-9.986l1.266-.477c.937-.353 2.292-.673 3.725-1.01 4.782-1.125 11.332-2.667 13.167-8.333 1.263-3.9.83-6.832-1.292-8.716-4.257-3.79-12.674-.406-20.928 3.383-.741.34-1.323.61-1.691.758-11.587 4.659-21.056 9.032-28.685 19.192-7.928 10.554-12.775 24.372-13.655 38.904-.725 11.995 5.154 26.148 14.98 36.053 8.691 8.762 21.999 16.803 33.902 20.482 5.411 1.673 11.245 2.518 17.375 2.518.704 0 1.41-.011 2.122-.035 6.773-.213 12.097-1.995 17.732-3.882 1.397-.466 2.821-.945 4.309-1.41 6.425-2.01 18.831-8.667 23.645-14.523 3.403-4.141 3.357-11.218-.1-15.146-2.588-2.941-6.446-3.351-10.078-1.07z'}
              fill={theme.palette.primary.plainColor}
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
