import * as React from 'react'
import { useLocation } from 'react-router-dom'
import Input from '@mui/joy/Input'
import Link from '@mui/joy/Link'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import ListItemButton from '@mui/joy/ListItemButton'
import ListItemContent from '@mui/joy/ListItemContent'
import Typography from '@mui/joy/Typography'
import { LayoutDashboard, Search } from 'lucide-react'

import SidebarWorkspacesSelectedItems from './sidebar-workspaces-selected-items.component'

export default function SidebarWorkspaceItems() {
  const location = useLocation()
  const { pathname } = location

  if (!pathname.startsWith('/workspaces')) {
    return null
  }

  return (
    <>
      <Input
        placeholder={'Search'}
        size={'sm'}
        startDecorator={<Search />} />
      <List
        size={'sm'}
        sx={{
          gap: 1,
          '--List-nestedInsetStart': '30px',
          '--ListItem-radius': (theme) => theme.vars.radius.sm
        }}
      >

        <ListItem>
          <ListItemButton
            component={Link}
            href={'/workspaces'}
          >
            <LayoutDashboard />
            <ListItemContent>
              <Typography>
                Workspaces
              </Typography>
            </ListItemContent>
          </ListItemButton>
        </ListItem>

        <SidebarWorkspacesSelectedItems />
      </List>
    </>
  )
}
