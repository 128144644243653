import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '@mui/joy/Button'

import FormField from 'components/form/form-field'
import isEmpty from 'utils/isEmpty'

import { usePageCreate } from './pages-create.hook'

export default function PagesCreatePage() {

  const navigate = useNavigate()
  const { workspaceId } = useParams()

  const {
    register,
    handleSubmit,
    formState: { errors },
    loading,
    createdPage
  } = usePageCreate()

  useEffect(() => {
    if (!isEmpty(createdPage)) {
      setTimeout(() => {
        navigate(`/workspaces/${workspaceId}/pages/${createdPage?.id}`, { replace: true })

      }, 1000)
    }
  }, [createdPage])

  if (!isEmpty(createdPage)) {
    return (
      <>
        page is created.
        <br />
        Opening to new page
      </>
    )
  }
  return (
    <form
      onSubmit={handleSubmit}
      noValidate>
      <FormField
        field={errors.title}
        placeholder={'Title'}
        type={'text'}
        {...register('title', { required: true })}
      />
      <FormField
        field={errors.title}
        placeholder={'URL Path'}
        type={'text'}
        {...register('url_path', { required: true })}
      />
      <Button
        loading={loading}
        type={'submit'}>
        Create
      </Button>
    </form>
  )
}
