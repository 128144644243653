import React from 'react'
import { FieldError } from 'react-hook-form/dist/types/errors'
import Checkbox, { CheckboxProps } from '@mui/joy/Checkbox'
import FormControl from '@mui/joy/FormControl'
import FormHelperText from '@mui/joy/FormHelperText'

import omit from '../../../utils/omit'

interface FormCheckboxProps extends CheckboxProps {
  field?: FieldError
}

function FormCheckbox({ field, ...checkboxRest }: FormCheckboxProps, ref: React.ForwardedRef<HTMLInputElement>) {
  const isRequired = field?.type === 'required'

  return (
    <FormControl
      error={isRequired}>
      <Checkbox
        ref={ref}
        color={'primary'}
        variant={'outlined'}
        {...omit(checkboxRest, ['watch'])}
      />
      {isRequired && (
        <FormHelperText>
          This field is required
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default React.forwardRef(FormCheckbox)
