import React from 'react'
import LandingPage from 'pages/landing'

import authRouter from './root-auth.router'
import profileRouter from './root-profile.router'
import supportRouter from './root-support.router'
import workspacesRouter from './root-workspaces.router'

const rootRouter = [
  authRouter,
  workspacesRouter,
  supportRouter,
  profileRouter,
  {
    path: '',
    element: <LandingPage />
  }
]

export default rootRouter
