import Button from '@mui/joy/Button'
import ButtonGroup from '@mui/joy/ButtonGroup'
import Checkbox from '@mui/joy/Checkbox'
import Divider from '@mui/joy/Divider'
import IconButton from '@mui/joy/IconButton'
import Link from '@mui/joy/Link'
import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'
import { ChevronDownCircle } from 'lucide-react'

import FormField from 'components/form/form-field'
import FormPasswordField from 'components/form/form-field/form-password-field.components'

import { useLogin } from './login.hook'

export default function LoginPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    loading
  } = useLogin()

  return (
    <>
      <Stack
        textAlign={'center'}>
        <Typography
          fontWeight={'md'}
          level={'h3'}>
          Welcome back
        </Typography>
        <Typography
          level={'body-sm'}>
          We're super happy to see you again!
        </Typography>
        <Typography
          level={'body-sm'}>
          Let's log you in.
        </Typography>
      </Stack>
      <form onSubmit={handleSubmit}>
        <Stack
          py={2}>
          <FormField
            color={'primary'}
            field={errors.email}
            placeholder={'Email'}
            type={'email'}
            {...register('email', { required: true })}
          />
        </Stack>
        <Stack
          py={2}
        >
          <FormPasswordField
            field={errors.password}
            placeholder={'Password'}
            noMeter
            {...register('password', { required: true })}
          />
        </Stack>
        <Checkbox label= {'Remember me'}/>
        <Stack
          pt={4}>
          <Button
            loading={loading}
            type={'submit'}>
            Login
          </Button>
        </Stack>
        <Stack
          alignItems={'center'}
          pt={2}
        >
          <Link
            href={'forgot-password'}>
           Forgot Password?
          </Link>
        </Stack>
      </form>
      <Divider inset={'none'}>
        <ChevronDownCircle
          size={'52px'}
          strokeWidth={'0.5px'}/>
      </Divider>
      <Stack
        alignItems={'center'}>
        <Typography
          level={'body-sm'}
          gutterBottom>
          Or login with social media
        </Typography>
        <ButtonGroup
          color={'primary'}
          size={'lg'}
          spacing={3.5}
        >
          <IconButton>
            G
          </IconButton>
          <IconButton>
            A
          </IconButton>
        </ButtonGroup>
      </Stack>
    </>
  )
}
