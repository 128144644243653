import React from 'react'
import PagesPage from 'pages/pages'
import PagesCreatePage from 'pages/pages/pages-create'
import PagesManagePage from 'pages/pages/pages-manage'
import PagesPagePage from 'pages/pages/pages-page'

const rootWorkspacesPagesRouter = {
  path: 'pages',
  element: <PagesPage />,
  children: [{
    path: 'create',
    element: <PagesCreatePage />
  }, {
    path: '',
    element: <PagesManagePage />
  }, {
    path: ':pageId',
    element: <PagesPagePage />
  }]
}

export default rootWorkspacesPagesRouter
