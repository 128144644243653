import React from 'react'
import { Outlet } from 'react-router-dom'
import Card from '@mui/joy/Card'
import Container from '@mui/joy/Container'
import Stack from '@mui/joy/Stack'

export default function AuthPage() {
  return (
    <Container maxWidth={'lg'}>
      <Stack
        direction={'row'}
        justifyContent={'center'}
        py={{
          xs: 5,
          md: 15
        }}>
        <Card
          variant={'outlined'}
          sx={{
            width: 500,
            display: 'flex',
            gap: 2,
            px: 6
          }}>
          <Outlet />
        </Card>
      </Stack>
    </Container>
  )
}
