import React from 'react'

export default function SupportTermsPage() {

  return (
    <>
      SupportTermsPage
    </>
  )
}
