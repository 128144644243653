import React from 'react'
import { Outlet } from 'react-router-dom'
import Container from '@mui/joy/Container'

export default function WorkspacesPage() {

  return (
    <Container
      maxWidth={false}
      sx={{
        pl: {
          md: 'var(--Sidebar-width)'
        }
      }}
    >
      <Outlet />
    </Container>
  )
}
