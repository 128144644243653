import React from 'react'

export default function WorkspaceOverviewPage() {

  return (
    <>
      WorkspaceOverviewPage
    </>
  )
}
