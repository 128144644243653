import * as React from 'react'
import { useLocation } from 'react-router-dom'
import Button from '@mui/joy/Button'
import Card from '@mui/joy/Card'
import IconButton from '@mui/joy/IconButton'
import LinearProgress from '@mui/joy/LinearProgress'
import Link from '@mui/joy/Link'
import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'
import { X } from 'lucide-react'

interface WorkspacePlanProps {
  baseUrl: string
}

export default function WorkspacePlan({ baseUrl }: WorkspacePlanProps) {
  const location = useLocation()
  const { pathname } = location

  const isOnPlanRoute = pathname.startsWith(`${baseUrl}/plan`)

  if (isOnPlanRoute) {
    return null
  }

  return (
    <Card
      color={'warning'}
      size={'sm'}
      sx={{ boxShadow: 'none' }}
      variant={'soft'}
      invertedColors
    >
      <Stack
        alignItems={'center'}
        direction={'row'}
        justifyContent={'space-between'}>
        <Typography level={'title-sm'}>Used space</Typography>
        <IconButton size={'sm'}>
          <X />
        </IconButton>
      </Stack>
      <Typography level={'body-xs'}>
        Your team has used 80% of your available space. Need more?
      </Typography>
      <LinearProgress
        sx={{ my: 1 }}
        value={80}
        variant={'outlined'}
        determinate />
      <Button
        component={Link}
        href={`${baseUrl}/plan`}
        size={'sm'}
        variant={'solid'}
      >
        Upgrade plan
      </Button>
    </Card>
  )
}
