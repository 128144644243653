import React from 'react'
import SupportPage from 'pages/support'
import SupportOverviewPage from 'pages/support/support-overview'
import SupportTermsPage from 'pages/support/support-terms'

const supportRouter = {
  path: 'support',
  element: <SupportPage />,
  children: [
    {
      path: 'terms',
      element: <SupportTermsPage />
    }, {
      path: '',
      element: <SupportOverviewPage />
    }
  ]
}

export default supportRouter
