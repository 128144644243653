import React from 'react'
import ProfilePage from 'pages/profile'
import ProfileOverviewPage from 'pages/profile/profile-overview'
import ProfileSettingsPage from 'pages/profile/profile-settings'

const profileRouter = {
  path: 'profile',
  element: <ProfilePage />,
  children: [
    {
      path: 'settings',
      element: <ProfileSettingsPage />
    }, {
      path: '',
      element: <ProfileOverviewPage />
    }
  ]
}

export default profileRouter
