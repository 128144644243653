import React from 'react'
import Grid from '@mui/joy/Grid'
import Link from '@mui/joy/Link'

import WorkspaceItem from 'components/workspace-item'
import useWorkspaces from 'modules/state/workspace.hook'

export default function WorkspacesManagePage() {
  // TODO: Display list of workspace with option to select or to
  //  create new

  // TODO: When selected persist it for next login
  //  When this page is reached and persisted workspace id exists just navigate to that workspaces overview page

  const { workspaces } = useWorkspaces()

  return (
    <>
      Workspaces Manage
      <Grid container>
        {workspaces?.map((workspace) => (
          <WorkspaceItem
            key={workspace.id}
            {...workspace} />
        ))}
      </Grid>

      <Link
        href={'create'}
      >
        Create new Workspace
      </Link>
    </>
  )
}
