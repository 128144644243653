import { styled } from '@mui/joy/styles'

const ContainerWrapper = styled('div')(({ theme }) =>({
  height: '90vh',
  position: 'relative',
  backgroundColor: theme.palette.background.body
}))
export default function LandingManagement() {

  return (
    <ContainerWrapper>
    Landing management
    </ContainerWrapper>
  )
}