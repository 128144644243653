import React, { useEffect } from 'react'

import cookie from 'modules/cookie'

import Rest, { ListResponse, RestContext } from '../rest'

import { Invalidate, Workspace } from './state.types'

type Workspaces = Invalidate<Workspace[]> & {
  workspaces?: Workspace[] | null
}

export default function useWorkspaces(onMount = true): Workspaces {

  const { workspaces, update } = React.useContext(RestContext)

  const fetchWorkspaces = React.useCallback(async (): Promise<Workspace[] | null> => {
    const token = cookie.get('token')

    if (!token) {
      return []
    }

    try {
      // TODO: Check if user is logged in or if there is a token when app is loading
      const response: ListResponse<Workspace> = await Rest.workspaces.all()
      const workspaces = response.results
      update('workspaces', workspaces)
      return workspaces
    } catch {
      return []
    }

  }, [])

  useEffect(() => {
    if (onMount) {
      fetchWorkspaces()
    }

  }, [onMount])

  function invalidate() {
    return fetchWorkspaces()
  }

  return {
    workspaces,
    invalidate
  }
}
