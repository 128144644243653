import * as React from 'react'
import { useLocation } from 'react-router-dom'
import Box from '@mui/joy/Box'
import Divider from '@mui/joy/Divider'
import GlobalStyles from '@mui/joy/GlobalStyles'
import Link from '@mui/joy/Link'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton'
import Stack from '@mui/joy/Stack'
import { LifeBuoy, Power, UserCog } from 'lucide-react'

import Logo from 'components/logo'
import useMe from 'modules/state/me.hook'
import { closeWorkspaceSidebar } from 'modules/state/workspace.state'

import SidebarProfileItems from './sidebar-profile-items'
import SidebarSupportItems from './sidebar-support-items'
import SidebarWorkspacesItems from './sidebar-workspaces-items'

export default function Sidebar() {
  const location = useLocation()
  const { pathname } = location

  const me = useMe()
  if (!me.isLoggedIn) {
    return null
  }
  const isSupportedPath = pathname.startsWith('/support')

  return (
    <Stack
      className={'Sidebar'}
      sx={{
        position: { xs: 'fixed' },
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none'
        },
        transition: 'transform 0.4s, width 0.4s',
        zIndex: 10000,
        height: {
          xs: '100vh',
          md: 'calc(100vh - var(--Header-height, 0))'
        },
        width: 'var(--Sidebar-width)',
        top: {
          xs: 0,
          md: 'var(--Header-height, 0)'
        },
        p: 2,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        backgroundColor: 'background.body'
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Sidebar-width': '220px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px'
            }
          }
        })}
      />
      <Box
        className={'Sidebar-overlay'}
        onClick={closeWorkspaceSidebar}
        sx={{
          position: 'fixed',
          zIndex: 9998,
          top: 0,
          left: 0,
          width: '100vw',
          height: 'calc(100vh - var(--Header-height, 0))',
          opacity: 'var(--SideNavigation-slideIn)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
            lg: 'translateX(-100%)'
          }
        }}
      />
      <Link
        href={''}
        sx={{ display: { md: 'none' } }}
      >
        <Logo />
      </Link>

      <Box
        sx={{
          minHeight: 0,
          overflow: 'hidden auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5
          }
        }}
      >
        <SidebarWorkspacesItems />
        <SidebarSupportItems />
        <SidebarProfileItems />

        <List
          size={'sm'}
          sx={{
            mt: 'auto',
            flexGrow: 0,
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
            '--List-gap': '8px',
            mb: 2
          }}
        >
          {!isSupportedPath && (
            <ListItem>
              <ListItemButton
                component={Link}
                href={'/support'}
              >
                <LifeBuoy />
              Support
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Box>
      <Divider />
      <List
        size={'sm'}
        sx={{
          mt: 'auto',
          flexGrow: 0,
          '--ListItem-radius': (theme) => theme.vars.radius.sm,
          '--List-gap': '8px',
          mb: 2
        }}
      >
        <ListItem>
          <ListItemButton
            component={Link}
            href={'/profile'}
          >
            <UserCog />
            {me.name || me.email}
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            onClick={me.logout}
          >
            <Power />
            Logout
          </ListItemButton>
        </ListItem>
      </List>
    </Stack>
  )
}
