import React from 'react'

export default function WorkspaceMessagesPage() {

  return (
    <>
      WorkspaceMessagesPage
    </>
  )
}
