import React from 'react'
import { useForm } from 'react-hook-form'

import Rest from 'modules/rest'

type RequestAccountInputs = {
  email: string
  terms_accepted: boolean
}

type RequestAccountResponse = {
  token: string
  user: string
}

export function useRequestAccount() {
  const [loading, setLoading] = React.useState(false)
  const [captcha, setCaptcha] = React.useState<string | null>(null)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<RequestAccountInputs>()

  function requestAccount(data: RequestAccountInputs) {
    setLoading(true)
    Rest.requestAccount.post({
      ...data,
      captcha
    }).then(async (response: RequestAccountResponse) => {
      // TODO: Display success message with some info and that mail will be send with small instruction
      console.log(response)
    }).catch((error) => {
      // TODO: error message
      console.log(error)
    }).finally(() => {
      setLoading(false)
    })
  }

  return {
    register: register,
    handleSubmit: handleSubmit(requestAccount),
    formState: { errors },
    loading,
    setCaptcha
  }
}
