import { useParams } from 'react-router-dom'
import {
  CheckCheck, Gauge, Layers, MessagesSquare, Settings, Tag, Users
} from 'lucide-react'

import SidebarItem from '../components/sidebar-item'

import WorkspacePlan from './workspace-plan'

export default function SidebarWorkspacesSelectedItems() {
  const { workspaceId } = useParams()

  const baseUrl = `/workspaces/${workspaceId}`

  if (!workspaceId) {
    return null
  }

  return (
    <>
      <SidebarItem
        href={baseUrl}
        icon={<Gauge />}
        title={'Overview'}
        exact
      />
      <SidebarItem
        href={`${baseUrl}/pages`}
        icon={<Layers />}
        title={'Pages'} />
      <SidebarItem
        href={`${baseUrl}/tasks`}
        icon={<CheckCheck />}
        title={'Tasks'} />
      <SidebarItem
        href={`${baseUrl}/tags`}
        icon={<Tag />}
        title={'Tags'} />
      <SidebarItem
        href={`${baseUrl}/messages`}
        icon={<MessagesSquare />}
        title={'Messages'} />
      <SidebarItem
        href={`${baseUrl}/members`}
        icon={<Users />}
        title={'Members'} />
      <SidebarItem
        href={`${baseUrl}/settings`}
        icon={<Settings />}
        title={'Settings'} />

      <WorkspacePlan baseUrl={baseUrl} />
    </>
  )
}
