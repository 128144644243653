import React from 'react'

import LandingMain from './landing-main'
import LandingManagement from './landing-management'

export default function LandingPage() {

  return (
    <>
      <LandingMain />
      <LandingManagement />
    </>
  )
}
