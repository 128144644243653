import * as React from 'react'
import { useLocation } from 'react-router-dom'
import Link from '@mui/joy/Link'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import ListItemButton from '@mui/joy/ListItemButton'
import ListItemContent from '@mui/joy/ListItemContent'
import Typography from '@mui/joy/Typography'
import { HeartHandshake, LayoutDashboard, LifeBuoy } from 'lucide-react'

export default function SidebarSupportItems() {
  const location = useLocation()
  const { pathname } = location

  const baseUrl = '/support'

  console.log('supp')
  if (!pathname.startsWith(baseUrl)) {
    return null
  }
  return (
    <List
      size={'sm'}
      sx={{
        gap: 1,
        '--List-nestedInsetStart': '30px',
        '--ListItem-radius': (theme) => theme.vars.radius.sm
      }}
    >
      <ListItem>
        <ListItemButton
          component={Link}
          href={'/workspaces'}
        >
          <LayoutDashboard />
          <ListItemContent>
            <Typography>
              Workspaces
            </Typography>
          </ListItemContent>
        </ListItemButton>
      </ListItem>

      <ListItem>
        <ListItemButton
          component={Link}
          href={baseUrl}
        >
          <LifeBuoy />
          <ListItemContent>
            <Typography>
              Overview
            </Typography>
          </ListItemContent>
        </ListItemButton>
      </ListItem>

      <ListItem>
        <ListItemButton
          component={Link}
          href={`${baseUrl}/terms`}>
          <HeartHandshake />
          <ListItemContent>
            <Typography>
              Terms
            </Typography>
          </ListItemContent>
        </ListItemButton>
      </ListItem>
    </List>
  )
}
