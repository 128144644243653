import React from 'react'
import { FieldError } from 'react-hook-form/dist/types/errors'
import FormControl from '@mui/joy/FormControl'
import FormHelperText from '@mui/joy/FormHelperText'
import Input, { InputProps } from '@mui/joy/Input'

import omit from 'utils/omit'

interface FormFieldProps extends InputProps {
  field?: FieldError
}

function FormField({ field, value, ...inputRest }: FormFieldProps, ref: React.ForwardedRef<HTMLInputElement>) {

  const isRequired = field?.type === 'required'

  return (
    <FormControl
      error={isRequired}>
      <Input
        ref={ref}
        color={'primary'}
        {...omit(inputRest, ['watch'])}
      />
      {isRequired && (
        <FormHelperText>
          This field is required
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default React.forwardRef(FormField)
