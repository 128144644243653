import React from 'react'
import { useForm } from 'react-hook-form'
import { Keys } from 'react-hook-form/dist/types/path/common'
import { RegisterOptions } from 'react-hook-form/dist/types/validator'

import Rest from 'modules/rest'

type WorkspaceCreateInputs = {
  title: string
  description?: string
}

type WorkspaceCreateResponse = {
  id: string
}

export function useWorkspaceCreate() {
  const [loading, setLoading] = React.useState(false)
  const [createdWorkspace, setCreatedWorkspace] = React.useState<WorkspaceCreateResponse | null>(null)

  const {
    register: formRegister,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<WorkspaceCreateInputs>()

  function create(data: WorkspaceCreateInputs) {
    setLoading(true)
    return Rest.workspaces.post(data).then((response: WorkspaceCreateResponse) => {
      console.log(response)

      setCreatedWorkspace(response)
      return response
    }).catch((error) => {
      // TODO: error message
      console.log(error)
    }).finally(() => {
      setLoading(false)
    })
  }

  return {
    register: (name: Keys<WorkspaceCreateInputs>, options?: RegisterOptions<WorkspaceCreateInputs, Keys<WorkspaceCreateInputs>>) => ({ ...formRegister(name, options), watch }),
    handleSubmit: handleSubmit(create),
    formState: { errors },
    loading,
    watch,
    createdWorkspace
  }
}
