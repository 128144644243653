import React, { useEffect } from 'react'

import cookie from 'modules/cookie'

import Rest, { ListResponse, RestContext } from '../rest'

import { Invalidate, Page } from './state.types'

type Pages = Invalidate<Page[]> & {
  pages?: Page[] | null
}

export default function usePages(workspace: string | undefined, onMount = true): Pages {

  const { pages, update } = React.useContext(RestContext)

  const fetchPages = React.useCallback(async (): Promise<Page[] | null> => {
    if (!workspace) {
      return []
    }

    const token = cookie.get('token')

    if (!token) {
      return []
    }

    try {
      // TODO: Check if user is logged in or if there is a token when app is loading
      const response: ListResponse<Page> = await Rest.pages.all({ workspace })
      const pages = response.results
      update('pages', pages)
      return pages
    } catch {
      return []
    }

  }, [])

  useEffect(() => {
    if (onMount) {
      fetchPages()
    }

  }, [onMount])

  function invalidate() {
    return fetchPages()
  }

  return {
    pages,
    invalidate
  }
}
