import React from 'react'
import WorkspacePage from 'pages/workspace'
import WorkspaceMembersPage from 'pages/workspace/workspace-members'
import WorkspaceMessagesPage from 'pages/workspace/workspace-messages'
import WorkspaceOverviewPage from 'pages/workspace/workspace-overview'
import WorkspacePlanPage from 'pages/workspace/workspace-plan'
import WorkspaceSettingsPage from 'pages/workspace/workspace-settings'
import WorkspaceTagsPage from 'pages/workspace/workspace-tags'
import WorkspaceTasksPage from 'pages/workspace/workspace-tasks'
import WorkspacesPage from 'pages/workspaces'
import WorkspacesCreate from 'pages/workspaces/workspaces-create'
import WorkspacesManage from 'pages/workspaces/workspaces-manage'

import rootWorkspacesPagesRouter from './root-workspaces-pages.router'

const workspacesRouter = {
  path: 'workspaces',
  element: <WorkspacesPage />,
  children: [
    {
      path: 'create',
      element: <WorkspacesCreate />
    }, {
      path: ':workspaceId',
      element: <WorkspacePage />,
      children: [
        {
          path: 'members',
          element: <WorkspaceMembersPage />
        }, {
          path: 'messages',
          element: <WorkspaceMessagesPage />
        },
        rootWorkspacesPagesRouter,
        {
          path: 'plan',
          element: <WorkspacePlanPage />
        }, {
          path: 'settings',
          element: <WorkspaceSettingsPage />
        }, {
          path: 'tags',
          element: <WorkspaceTagsPage />
        }, {
          path: 'tasks',
          element: <WorkspaceTasksPage />
        }, {
          path: '',
          element: <WorkspaceOverviewPage />
        }]
    }, {
      path: '',
      element: <WorkspacesManage />
    }]
}

export default workspacesRouter
