import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/joy/Button'
import Container from '@mui/joy/Container'
import GlobalStyles from '@mui/joy/GlobalStyles'
import IconButton from '@mui/joy/IconButton'
import Link from '@mui/joy/Link'
import Stack from '@mui/joy/Stack'
import { Menu } from 'lucide-react'

import Logo from 'components/logo'
import ModeToggle from 'components/mode-toggle'
import useMe from 'modules/state/me.hook'
import { toggleWorkspaceSidebar } from 'modules/state/workspace.state'

export default function Header() {
  const { t } = useTranslation()
  const me = useMe()

  return (
    <Container maxWidth={me.isLoggedIn ? false: 'lg'}>
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Header-height': '52px'
          }
        })}
      />
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        py={1}>
        <Stack
          alignItems={'center'}
          direction={'row'}
          gap={1}
          justifyContent={'center'}
        >
          {me.isLoggedIn && (
            <IconButton
              color={'neutral'}
              onClick={toggleWorkspaceSidebar}
              size={'sm'}
              variant={'outlined'}
              sx={{
                display: { md: 'none' }
              }}
            >
              <Menu />
            </IconButton>
          )}
          <Link href={'/'}>
            <Logo />
          </Link>
        </Stack>

        <Stack
          alignItems={'center'}
          direction={'row'}
          gap={1}>

          <ModeToggle />

          {!me.isLoggedIn && (
            <>
              <Button
                component={Link}
                href={'/auth'}
                variant={'plain'}>
                {t('Login')}
              </Button>
              <Button
                component={Link}
                href={'/auth/register'}
                variant={'outlined'}>
                {t('Register')}
              </Button>
            </>
          )}
        </Stack>
      </Stack>
    </Container>
  )
}
