import React from 'react'
import { useColorScheme } from '@mui/joy/styles'
import Switch from '@mui/joy/Switch'
import { Moon,Sun } from 'lucide-react'

export default function ModeToggle() {
  const { mode, setMode } = useColorScheme()
  const [mounted, setMounted] = React.useState(false)

  // necessary for server-side rendering
  // because mode is undefined on the server
  React.useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted) {
    return null
  }

  return (
    <Switch
      checked={mode === 'dark'}
      size={'lg'}
      onChange={({ target: { checked } }) => {
        setMode(checked ? 'dark' : 'light')
      }}
      slotProps={{
        input: { 'aria-label': 'Dark mode' },
        thumb: {
          children: mode === 'light'? <Sun /> : <Moon />
        }
      }}
    />
  )
}
